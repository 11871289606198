import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import Paragraph from "../common/Paragraph";
import useMultipleRefs from "../../utils/useMultipleRefs";
import scrollToElement from "../../utils/scrollToElement";
import { getShareImage } from "../../utils/getShareImage";

const PrivacyPolicy = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const [elRefs] = useMultipleRefs({ arrLength: t.content.length });

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Terms">
        <div className="container Terms__container">
          <div className="Terms__menu">
            <p className="Terms__menu__title">{t.menu}</p>
            <ul>
              {t.content.map((elem, index) => (
                <li key={index}>
                  <Link to={`#${elem.id}`} onClick={e => scrollToElement({ e, ref: elRefs[index].current })}>
                    {elem.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="Terms__content">
            {t.content.map((elem, index) => (
              <Paragraph key={index} ref={elRefs[index]} t={elem} nr={index + 1} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

PrivacyPolicy.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default PrivacyPolicy;
